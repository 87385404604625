.ant-table-thead > tr > th {
  background: #141e31;
  color: #5c91fc;
}

.ant-table-row {
  background: #141e31;
  color: white;
}
.ant-table-cell-fix-left,
.ant-table-cell-fix-right {
  background: #1c1f35;
  color: white;
}
.ant-table-tbody > tr.ant-table-row:hover > td,
td.ant-table-column-sort,
.ant-table-thead th.ant-table-column-sort {
  background: #0c111b !important;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  border-bottom: 1px solid black !important;
}

.ant-table-cell:hover {
  background-color: #1c1f35 !important;
}

.ant-table {
  background: #1c1f35;
}

#form .ant-form-item-label > label {
  color: white !important;
}
