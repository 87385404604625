@import './mixin';
@import (reference) '../../style/themes/index';
@input-prefix-cls: ~'@{ant-prefix}-input';

@input-affix-margin: 4px;

.@{ant-prefix}-input {
  &-affix-wrapper {
    .input();
    display: inline-flex;

    &:not(&-disabled):hover {
      .hover();
      z-index: 1;
      .@{ant-prefix}-input-search-with-button & {
        z-index: 0;
      }
    }

    &-focused,
    &:focus {
      z-index: 1;
    }

    &-disabled {
      .@{ant-prefix}-input[disabled] {
        background: transparent;
      }
    }

    > input.@{ant-prefix}-input {
      padding: 0;
      font-size: inherit;
      border: none;
      outline: none;

      &:focus {
        box-shadow: none !important;
      }
    }

    &::before {
      width: 0;
      visibility: hidden;
      content: '\a0';
    }
  }

  &-prefix,
  &-suffix {
    display: flex;
    flex: none;
    align-items: center;

    > *:not(:last-child) {
      margin-right: 8px;
    }
  }

  &-show-count-suffix {
    color: @text-color-secondary;
  }

  &-show-count-has-suffix {
    margin-right: 2px;
  }

  &-prefix {
    margin-right: @input-affix-margin;
  }

  &-suffix {
    margin-left: @input-affix-margin;
  }
}
